@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.articleDuetBody {
  :global(.share__container--article a),
  :global(.share__container--article svg path),
  :global(.share__link-copy .icon-link) {
    color: var(--share-link-copy-color);
    fill: var(--share-link-copy-color);
  }
}
