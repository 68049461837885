@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.heading {
  @include h.breakpoint-x {
    max-width: calc(var(--bp-x-width) / 2);
  }

  @media (min-width: 1600px) {
    max-width: calc(1680px / 2);
  }
}

.eyebrow {
  color: var(--text-color);
  font-family: var(--eyebrow-font);
  text-transform: uppercase;
  font-size: var(--eyebrow-size);
  font-weight: var(--eyebrow-weight);
  line-height: var(--eyebrow-leading);
  margin-bottom: var(--eyebrow-vert-gutter);
  transition: opacity .25s ease-in-out;

  &:hover {
    color: var(--eyebrow-hover-color);
  }
}

.headline {
  color: var(--text-color);
  font-family: var(--headline-font);
  font-weight: var(--headline-weight);
  font-size: var(--headline-size);
  line-height: var(--headline-leading);
  margin-top: 0;
  margin-bottom: var(--headline-vert-gutter);
}

.dek {
  margin: 0;
  color: var(--dek-color);
  font-family: var(--dek-font);
  font-size: var(--dek-size);
  line-height: var(--dek-leading);
}
