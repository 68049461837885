@use 'assets/styles/utils/helper' as h;

.heading {
  --text-color: var(--white);
  --eyebrow-hover-color: var(--text-color);
  --eyebrow-font: var(--founders-mono);
  --eyebrow-size: #{h.rem(12px)};
  --eyebrow-weight: 600;
  --eyebrow-leading: 1;
  --eyebrow-vert-gutter: #{h.rem(8px)};
  --headline-font: var(--founders-cond);
  --headline-size: #{h.rem(32px)};
  --headline-weight: 600;
  --headline-leading: 1;
  --headline-vert-gutter: #{h.rem(12px)};
  --dek-font: var(--publico-hed);
  --dek-size: #{h.rem(16px)};
  --dek-leading: 1.25;
  --dek-color: var(--white);

  @include h.breakpoint-m {
    --eyebrow-vert-gutter: #{h.rem(12px)};
    --headline-size: #{h.rem(40px)};
    --headline-vert-gutter: #{h.rem(16px)};
    --dek-size: #{h.rem(18px)};
  }

  @include h.breakpoint-l {
    // Header text scales with viewport between a minimum and maximum font size
    --headline-size: clamp(var(--text-40), 3vw, var(--text-60));
    --dek-size: clamp(var(--text-18), 1.25vw, var(--text-24));
  }

  :global(body.today) & {
    --text-color: var(--grey-70);
    --eyebrow-hover-color: var(--secondary-color);
    --eyebrow-font: var(--secondary-font);
    --eyebrow-weight: 700;
    --eyebrow-vert-gutter: #{h.rem(18px)};
    --headline-font: var(--primary-font);
    --headline-size: #{h.rem(24px)};
    --headline-weight: 400;
    --headline-leading: 1.5;
    --headline-vert-gutter: #{h.rem(14px)};
    --dek-font: var(--tertiary-font);
    --dek-color: var(--grey-60);
    --dek-size: #{h.rem(20px)};
    --dek-leading: 1.5;

    @include h.breakpoint-l {
      --headline-size: #{h.rem(35px)};
      --headline-vert-gutter: #{h.rem(24px)};
      --dek-size: #{h.rem(18px)};
    }
  }
}
