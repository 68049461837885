@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

html {
  scroll-padding-top: 10rem;
}

.article {
  background-color: var(--article-page-background);
}

.hasStickyVideo {
  z-index: h.get-z-index('page') + 1;
}
