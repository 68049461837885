@use 'assets/styles/utils/helper' as h;

.mainMedia {
  --media-position: 50% 50%;
  --media-max-height: 105vw;
  --media-height: auto;

  @include h.breakpoint-m {
    --media-max-height: 65vh;
  }

  @include h.breakpoint-l {
    --media-max-height: unset;
    --media-height: var(--fullScreen-height);
  }
}
