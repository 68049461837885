@use 'assets/styles/utils/helper' as h;

.articleDuetBody {
  --share-link-copy-color: var(--grey-50);
  --article-body-grid-container-no-main-padding: var(--space-16) 0 0;

  @include h.breakpoint-l {
    --article-body-grid-container-no-main-padding: var(--space-32) 0 0;
  }
}
