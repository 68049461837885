@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.mainMedia {
  img {
    max-width: 100%;
    display: block;
    height: var(--media-height);
    max-height: var(--media-max-height);
    object-fit: cover;
    object-position: var(--media-position);
  }
}
