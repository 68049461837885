@use 'assets/styles/utils/helper' as h;

.opaqueBackground {
  background: rgba(0, 0, 0, 0.5);
}

.container {
  bottom: h.rem(20px);
  right: h.rem(20px);
  height: h.rem(447px);
  width: h.rem(335px);

  :global(.news) & {
    background: linear-gradient(148.68deg, #0a2768 4.88%, #1055c0 93.36%);
  }

  :global(.today) & {
    background: linear-gradient(90deg, #ff503c 0%, rgba(255, 255, 255, 0) 100%), #720172;
  }

  :global(.mach) & {
    background: linear-gradient(270deg, #ce1e6c 0%, #5c00d8 108.99%, #5c00d8 108.99%);
  }

  :global(.think) & {
    background: linear-gradient(270deg, #ff5042 0%, #000 108.99%);
  }

  :global(.globalcitizen) & {
    background: linear-gradient(90deg, #b2ccce 0%, #b6001a 100%);
  }

  :global(.msnbc) & {
    background: linear-gradient(145.59deg, #135270 4.55%, #51f1e2 90.57%);
  }

  :global(.better) & {
    background: linear-gradient(270deg, #21d1aa 0%, #2a0547 108.99%);
  }

  @include h.breakpoint-l {
    height: h.rem(320px);
    width: h.rem(720px);
    bottom: h.rem(39px);
    right: h.rem(39px);
  }
}

.content {
  height: h.rem(467px);
  width: h.rem(335px);
  top: h.rem(20px);
  left: h.rem(20px);
  padding: h.rem(43px) h.rem(23px);
  background: var(--white);

  @include h.breakpoint-l {
    top: h.rem(39px);
    left: h.rem(39px);
    height: h.rem(340px);
    width: h.rem(720px);
    padding: h.rem(35px) h.rem(64px) h.rem(73px) h.rem(40px);
  }

  .dots {
    @include h.pattern-dots(var(--black));
    height: h.rem(38px);
    width: h.rem(77px);
    bottom: 0;
    right: 0;
  }
}
