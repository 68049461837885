@use 'assets/styles/utils/helper' as h;

.duetHero {
  --column-template: 1fr;
  --area-template:
    'top'
    'bottom';

  @include h.breakpoint-l {
    --column-template: 0fr 1fr 1fr 0fr;
    --area-template:
      'topL media heading topR'
      'caption caption caption caption';
    --default-nav-height: 60px;
    --fullScreen-height: calc(100vh - (var(--default-nav-height) + var(--breaking-marquee-init-height, 0px)));

    .hasNoMainMedia & {
      --duet-col: calc(var(--bp-l-width) / 2);
      --column-template: 1fr repeat(2, var(--duet-col)) 1fr;
      --area-template: 'topL heading heading topR';
    }
  }

  @include h.breakpoint-x {
    .hasNoMainMedia & {
      --duet-col: calc(var(--bp-x-width) / 2);
    }
  }
}

.duetHeroContainer {
  --duet-hero-background-color: var(--black);

  :global(body.today) & {
    --duet-hero-background-color: var(--grey-20);
  }
}
