@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.duetLayout {
  background-color: var(--default-background);
}

.duetHeroContainer {
  background-color: var(--duet-hero-background-color);
  margin-bottom: var(--space-16);

  @include h.breakpoint-m {
    // Account for the MyNews Tooltip appended to the sharebar below
    margin-bottom: var(--space-32);
  }

  .hasNoMainMedia & {
    margin-bottom: 0;
  }
}

.duetHero {
  display: grid;
  grid-template-columns: var(--column-template);
  grid-template-rows: auto auto;
  grid-template-areas: var(--area-template);

  @include h.breakpoint-l {
    min-height: var(--fullScreen-height);
  }
}

.gridAreaMainMedia,
.gridAreaMainCaption {
  grid-area: top;
}

.gridAreaMainMedia {
  @include h.breakpoint-l {
    grid-area: media;
  }
}

.gridAreaMainCaption {
  align-self: end;

  @include h.breakpoint-l {
    background-color: var(--default-background);
    grid-area: caption;
  }

  @include h.breakpoint-x {
    display: grid;
    grid-template-columns: var(--column-template);
    grid-template-areas: 'bottom1 content content bottom2';
    grid-template-rows: auto;

    :global(.caption) {
      grid-area: content;
    }
  }
}

.gridAreaHeading {
  padding: var(--space-28) var(--space-20);

  @include h.breakpoint-m {
    --one-col-width: calc(var(--twelve-col-percentage) * 1);
    padding-left: calc(var(--one-col-width) + var(--outer-gutter));
    padding-right: calc(var(--one-col-width) + var(--outer-gutter));
  }

  @include h.breakpoint-l {
    grid-area: heading;
    padding-left: var(--space-60);
    padding-right: var(--space-80);

    .hasNoMainMedia & {
      padding: var(--space-40) var(--outer-gutter);
    }
  }

  @include h.breakpoint-x {
    grid-area: heading;
    padding-left: var(--space-80);
    padding-right: var(--space-100);

    .hasNoMainMedia & {
      --two-col-width: calc(var(--twelve-col-percentage) * 2);
      padding-left: calc(var(--two-col-width) + var(--outer-gutter));
      padding-right: var(--outer-gutter);
    }
  }
}

.gridAreaHeading,
.gridAreaMainMedia {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
