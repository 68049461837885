@use 'assets/styles/utils/helper' as h;

.mediaCaption {
  --caption--background-blur: blur(10px);
  --caption--background: rgba(0, 0, 0, 0.3);
  --caption--color: var(--white);
  --caption--source--color: var(--white);
  --caption--before--border-bottom-color: var(--white);
  --caption--text-align: left;

  @include h.breakpoint-l {
    --caption--color: unset;
    --caption--source--color: unset;
    --caption--before--border-bottom-color: unset;
  }

  :global(body.today) & {
    --caption--background-blur: unset;
    --caption--background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    --caption--text-align: center;

    @include h.breakpoint-l {
      --caption--text-align: left;
    }
  }
}
