@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.mediaCaption {
  position: relative;
  z-index: 2;

  figcaption {
    position: relative;
    z-index: 1;
    padding: var(--space-16) var(--space-20) var(--space-8) var(--space-20);
    text-align: var(--caption--text-align);
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  &::before {
    backdrop-filter: var(--caption--background-blur);
    mask-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 1));
  }

  &::after {
    background: var(--caption--background);
    filter: var(--caption--background-blur);
  }

  @include h.breakpoint-l {
    figcaption {
      padding-bottom: 0;
      padding-left: var(--space-40);
      padding-right: var(--space-40);
    }

    &::before,
    &::after {
      display: none;
    }
  }

  :global(.caption__source) {
    margin-left: 0;
    display: inline;
  }

  :global(.caption__container) {
    margin-right: var(--space-12);
  }
}
